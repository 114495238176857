
import uniqIdMixin from '~/mixins/uniq-id'

export default {
  name: 'BaseFormLabel',

  mixins: [
    uniqIdMixin,
  ],

  props: {
    label: {
      type: String,
      default: null,
    },

    labelFromMiddleware: {
      type: String,
      default: null,
    },

    labelImage: {
      type: String,
      default: null,
    },

    labelAltImage: {
      type: String,
      default: '',
    },

    labelParams: {
      type: Object,
      default: () => ({}),
    },

    labelDescription: {
      type: String,
      default: null,
    },

    hint: {
      type: String,
      default: null,
    },

    hintHtml: {
      type: String,
      default: null,
    },

    required: {
      type: Boolean,
      default: false,
    },

    hideRequiredStar: {
      type: Boolean,
      default: false,
    },

    mediFilterCategoryItemCount: {
      type: String,
      default: '',
    },

    isFirstLetterCapitalized: {
      type: Boolean,
      default: false,
    },
  },
}
