
import { ValidationProvider } from 'vee-validate'
import uniqIdMixin from '~/mixins/uniq-id'

export default {
  name: 'BaseFormScope',
  components: {
    ValidationProvider,
  },

  mixins: [
    uniqIdMixin,
  ],

  inheritAttrs: false,
  props: {
    value: {
      type: [String, Boolean, Number, Array],
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    labelFromMiddleware: {
      type: String,
      default: '',
    },

    labelBemModifiers: {
      type: Array,
      default: () => [],
    },

    labelParams: {
      type: Object,
      default: () => ({}),
    },

    labelDescription: {
      type: String,
      default: null,
    },

    placeholder: {
      type: String,
      default: null,
    },

    hint: {
      type: String,
      default: null,
    },

    hintHtml: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    name: {
      type: String,
      default: '',
    },

    rules: {
      type: [String, Object],
      default: '',
    },

    bails: {
      type: Boolean,
      default: true,
    },

    hideRequiredStar: {
      type: Boolean,
      default: false,
    },

    hideErrors: {
      type: Boolean,
      default: false,
    },

    autocomplete: {
      type: String,
      default: 'on',
    },

    wrapperBemModifiers: {
      type: String,
      default: '',
    },
  },

  methods: {
    onClickOutside () {
      this.$emit('click-outside')
    },
  },
}
