import { render, staticRenderFns } from "./BaseFormScope.vue?vue&type=template&id=7ebf7b5c&scoped=true&"
import script from "./BaseFormScope.vue?vue&type=script&lang=js&"
export * from "./BaseFormScope.vue?vue&type=script&lang=js&"
import style0 from "./BaseFormScope.vue?vue&type=style&index=0&id=7ebf7b5c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ebf7b5c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseFormLabel: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/form/BaseFormLabel.vue').default,BaseFormMessage: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/form/BaseFormMessage.vue').default})
