
import BaseFormScope from './BaseFormScope'

export default {
  name: 'BaseFormCheckbox',
  extends: BaseFormScope,
  props: {
    isSizeSmall: {
      type: Boolean,
      default: false,
    },
  },
}
